/*=====================
 4.6 Category Classic Css 
==========================*/
.catagories-classic {
  .search-box {
    @include search-box;
  }
  .catagories-wrap {
    .media {
      position: relative;
      background-color: $grey-light;
      border-radius: 8px;
      a {
        img {
          width: 100%;
          height: auto;
        }
      }
      @media only screen and (max-width: 375px) {
        align-items: center;
      }
      .media-body {
        width: calc(100% - 80px);
        a {
          font-weight: 600;
          font-size: 15px;
          margin-bottom: 2px;
          @media only screen and (max-width: 360px) {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
        p {
          margin-bottom: 0;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .arrow-nav {
        display: inline-block;
        margin-left: auto;
        margin-right: calc(-0px + (-10 + 0) * ((100vw - 320px) / (1920 - 320)));

        [dir="rtl"] & {
          margin-left: calc(-0px + (-10 + 0) * ((100vw - 320px) / (1920 - 320)));
          margin-right: auto;
          transform-origin: center;
          transform: rotate(180deg);
        }
        svg {
          width: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
          height: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
          stroke: $theme-color;
        }
      }
    }
  }
}
