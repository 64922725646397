/*=====================
  2.8 Switch Css 
==========================*/
.dark-switch {
  position: relative;
  width: 60px;
  height: 30px;
  border-radius: 40px;

  input[type="checkbox"] {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: 0px;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }
  span {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    overflow: hidden;
    opacity: 1;
    background-color: $white;
    border: $border1;
    border-radius: 40px;
    transition: 0.2s ease background-color, 0.2s ease opacity;
    &.before-none {
      &::before {
        background-color: transparent !important;
      }
    }
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 6px;
      width: 18px;
      height: 18px;
      opacity: 0.5;
      border-radius: 50%;
      transition: 0.5s ease transform, 0.2s ease background-color;
    }
    &:before {
      background-color: $white;
      transform: translate(-58px, 0px);
      z-index: 1;
    }
    &:after {
      background-color: $title-color;
      transform: translate(8px, 0px);
      z-index: 0;
      [dir="rtl"] & {
        transform: translate(-8px, 0px);
      }
    }
  }

  input[type="checkbox"]:checked + span {
    background-color: $white;
  }

  input[type="checkbox"]:active + span {
    opacity: 0.5;
  }

  input[type="checkbox"]:checked + span:before {
    background-color: $white;
    transform: translate(28px, -6px);
    opacity: 1;
    [dir="rtl"] & {
      transform: translate(-28px, -6px);
    }
  }

  input[type="checkbox"]:checked + span:after {
    background-color: $theme-color;
    transform: translate(34px, -1px);
    opacity: 1;
    [dir="rtl"] & {
      transform: translate(-34px, -1px);
    }
  }
}
