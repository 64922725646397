/*=====================
  2.2 Buttons Css 
==========================*/
.btn-solid {
  padding: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320))) calc(20px + (34 - 20) * ((100vw - 320px) / (1920 - 320)));
  background-color: $theme-color;
  color: $white;
  font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
  border-radius: 5px;
  font-weight: 600;
  border: $border2;
  line-height: 21px;
  display: inline-block;
  [class="dark"] & {
    color: $white-text-dark;
  }
  &:hover {
    color: $white;
    [class="dark"] & {
      color: $white-text-dark;
    }
  }
}
.btn-outline {
  padding: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320))) calc(28px + (34 - 28) * ((100vw - 320px) / (1920 - 320)));
  background-color: $white;
  color: $theme-color;
  font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
  border-radius: 5px;
  font-weight: 600;
  border: 1px solid $theme-color;
  display: inline-block;
  line-height: 21px;
  &:hover {
    color: $theme-color;
  }
}
.btn-outline-grey {
  padding: 10px calc(28px + (34 - 28) * ((100vw - 320px) / (1920 - 320)));
  background-color: $white;
  color: $content-color;
  font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
  border-radius: 5px;
  line-height: 21px;
  font-weight: 600;
  border: 1px solid $content-light;
  width: 100%;
  display: inline-block;
  &:hover {
    color: $content-color;
  }
}
