/*=====================
  4.4 Cart & Wishlist Page CSS 
==========================*/
.cart-page {
  .cart-item-wrap {
    .swipe-to-show {
      overflow-x: hidden;
      touch-action: none;
      position: relative;
      &:not(:first-of-type) {
        margin-top: 15px;
      }
      .media {
        width: 100%;
        background-color: $white-light;
        transition: all 0.4s;
        position: relative;
        z-index: 1;

        .media-body {
          span {
            .badges-round {
              [class="dark"] & {
                color: $white-text-dark;
              }
            }
          }
        }
      }
      .delete-button {
        display: flex;
        cursor: pointer;
        background-color: $theme-color;
        padding: 14px;
        align-items: center;
        justify-content: center;
        border-radius: 0 10px 10px 0;
        transition: all 0.4s;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -60px;
        z-index: 2;
        [dir="rtl"] & {
          right: unset;
          border-radius: 10px 0 0 10px;
          left: -60px;
        }
        svg {
          width: calc(20px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
          height: calc(20px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
          stroke: $white;
          [class="dark"] & {
            stroke: $white-text-dark;
          }
        }
      }
      &.active {
        .media {
          margin-left: -40px;
          [dir="rtl"] & {
            margin-right: -40px;
            margin-left: unset;
          }
        }
        .delete-button {
          right: 0;
          [dir="rtl"] & {
            left: 0;
            right: unset;
          }
        }
      }
    }
  }
  .coupon-ticket-wrap {
    margin-top: 20px;
    .coupon-ticket {
      cursor: pointer;
      background-color: $theme-light-color;
      padding: 20px;
      position: relative;
      font-family: $font-2;
      .media {
        margin-top: calc(-3px + (-7 + 3) * ((100vw - 320px) / (1920 - 320)));
        background-color: transparent;
        gap: calc(6px + (15 - 6) * ((100vw - 320px) / (1920 - 320)));
        padding: 0;

        .off {
          display: flex;
          align-items: center;
          gap: 4px;
          @media only screen and (max-width: 375px) {
            margin-top: -20px;
          }
          > span {
            &:first-of-type {
              font-size: calc(26px + (45 - 26) * ((100vw - 320px) / (1920 - 320)));
              font-weight: 700;
              color: $theme-color;
            }
            &:last-of-type {
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              span {
                font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
                font-weight: normal;
                color: $theme-color;
                &:first-of-type {
                  margin-bottom: calc(-6px + (-10 + 6) * ((100vw - 320px) / (1920 - 320)));
                  font-size: calc(17px + (24 - 17) * ((100vw - 320px) / (1920 - 320)));
                }
              }
            }
          }
        }
        .media-body {
          flex: 1 1 auto;
          @media only screen and (max-width: 375px) {
            position: absolute;
            left: 20px;
            bottom: 18px;
            [dir="rtl"] & {
              left: unset;
              right: 20px;
            }
          }
          h2,
          span {
            font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          }
          h2 {
            line-height: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
            margin-bottom: 0;
          }
          @media only screen and (max-width: 375px) {
            text-align: center;
            h2,
            span {
              font-size: 16px;
            }
            span {
              display: none;
            }
            h2 {
              margin-bottom: 0;
              line-height: 22px;
            }
          }
        }
      }
      .code {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 6px;
        @media only screen and (max-width: 375px) {
          margin-left: auto;
          [dir="rtl"] & {
            margin-right: auto;
            margin-left: unset;
          }
        }
        span {
          font-weight: 400;
          color: $title-color;
          font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
        }
        a {
          font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
          padding: calc(3px + (5 - 3) * ((100vw - 320px) / (1920 - 320))) calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
          line-height: 22px;
          background-color: $theme-color;
          color: $white;
          border-radius: 100px;
          [class="dark"] & {
            color: $white-text-dark;
          }

          @media only screen and (max-width: 375px) {
            font-size: 12px;
            padding: 3px 14px;
          }
        }
      }
      .big-circle {
        position: absolute;
        top: 50%;
        right: 24%;
        transform: translateY(-50%);
        [dir="rtl"] & {
          right: unset;
          left: 20%;
        }
        @media only screen and (max-width: 375px) {
          right: 30%;
          [dir="rtl"] & {
            left: 30%;
            right: unset;
          }
        }

        span {
          width: 40px;
          height: 40px;
          border-radius: 100%;
          background-color: transparent;
          display: block;
          position: relative;
          @media only screen and (max-width: 375px) {
            width: 30px;
            height: 30px;
          }
          &::before,
          &::after {
            content: "";
            left: 50%;
            transform: translateX(-50%);
            width: inherit;
            height: inherit;
            border-radius: inherit;
            background-color: $white;
            position: absolute;
          }
          &::before {
            top: -50px;
          }
          &::after {
            bottom: -50px;
          }
        }
        @media only screen and (max-width: 375px) {
          span {
            &::before {
              top: -45px;
            }
            &::after {
              bottom: -45px;
            }
          }
        }
      }
      .circle-5 {
        top: 32%;
        &.left {
          left: -6px;
        }
        &.right {
          right: -6px;
        }
      }
    }
  }
  .order-detail {
    h3 {
      margin-bottom: 10px;
    }
    @include order-summery;
  }
}
