/*=====================
4.8 Checkout Pages Css 
==========================*/

/// Address1 Page Css Start ///
.address1-page {
  padding: 0;
  .map-wrap {
    position: relative;
    .top-address {
      padding: 8px calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
      display: flex;
      align-items: center;
      gap: 10px;
      background-color: $white;
      border-radius: 5px;
      position: absolute;
      top: 10px;
      z-index: 2;
      width: fit-content;
      left: 20px;
      @media (max-width: 375px) {
        width: 90%;
      }
      svg {
        width: 20px;
        height: 20px;
      }
      p {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .map-section {
      height: 50vh;
      width: 100%;
      canvas {
        width: 100% !important;
      }
      .H_imprint {
        display: none;
      }
    }
    .navgator {
      padding: 8px;
      display: block;
      background-color: $white;
      position: absolute;
      right: 20px;
      bottom: 25px;
      border-radius: 100px;
      cursor: pointer;
      [dir="rtl"] & {
        left: 20px;
        right: unset;
      }
      svg {
        width: 22px;
        height: 22px;
        stroke: $content-color;
      }
    }
  }
  .location-section {
    border-radius: 20px 20px 0px 0px;
    background-color: $white;
    margin-top: -15px;
    position: relative;
    z-index: 2;
    padding: 18px 15px 15px 15px;
    .search-box {
      @include search-box;
    }
    .current-box {
      margin-top: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)));
      .media {
        span {
          padding: 7px;
          display: flex;
          border-radius: 4px;
          align-items: center;
          justify-content: center;
          background-color: $theme-color;
          svg {
            width: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
            height: calc(17px + (20 - 17) * ((100vw - 320px) / (1920 - 320)));
            stroke: $white;
            [class="dark"] & {
              stroke: $white-text-dark;
            }
            line {
              opacity: 0.5;
            }
          }
        }
        .media-body {
          h2 {
            font-weight: 600;
          }
        }
      }
      .location {
        margin-top: 7px;
        .location-box {
          padding: 18px 0;
          &:not(:last-of-type) {
            border-bottom: $border1;
          }
          h3 {
            display: flex;
            align-items: center;
            gap: 6px;
            i {
              font-size: 24px;
            }
          }
          p {
            margin-top: 7px;
            margin-bottom: 0;
          }
        }
      }
    }
    .btn-solid {
      display: block;
      text-align: center;
      margin-top: 15px;
    }
  }
}
/// Address1 Page Css End ///

/// Address2 Page Css Start ///
.address2-page {
  .address-wrap {
    .address-box {
      cursor: pointer;
      padding: 14px;
      background-color: $grey-light;
      border-radius: 15px;
      border: 2px solid transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.4s;
      margin-top: calc(17px + (22 - 17) * ((100vw - 320px) / (1920 - 320)));
      .conten-box {
        .heading {
          display: flex;
          align-items: center;
          gap: 8px;
          h2 {
            font-weight: 700;
          }
          i {
            font-size: calc(22px + (24 - 22) * ((100vw - 320px) / (1920 - 320)));
          }
        }
        h3 {
          margin-top: 14px;
          line-height: 18px;
          margin-bottom: 4px;
          font-weight: 500;
        }
        p {
          margin-bottom: 0;
          line-height: 18px;
          font-weight: normal;
        }
      }
      img {
        width: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
        height: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 15px;
        margin-left: 10px;
        [dir="rtl"] & {
          margin-right: 10px;
          margin-left: unset;
        }
      }
      &.active {
        border-color: $theme-color;
      }
    }
  }
}
/// Address2 Page Css End ///

/// Payment Page Css Start ///
.payment-page {
  .payment-section {
    .accordion {
      .accordion-item {
        &:first-of-type {
          .accordion-header {
            border-top: none;
          }
        }
        .accordion-header {
          padding: 15px 0;
        }
        .accordion-button {
          font-weight: 600;
          &::after {
            background-color: $theme-light-color;
            width: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
            height: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
            border-radius: 100%;
            background-position: center;

            [class="dark"] & {
              background-color: $white-text-dark;
              filter: invert(1);
            }
          }
        }
        .accordion-body {
          margin-top: 0;
          padding-bottom: 20px;
          &.net-banking {
            .row {
              > div {
                display: flex;
                align-items: center;
                @media only screen and (max-width: 567px) {
                  width: 100%;
                }
              }
            }
          }
          .filter-row {
            .filter-col {
              border: $border1;
              background-color: $grey-light;
              padding: 11px 11px;
              font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
              border-radius: 5px;
              font-weight: 600;
              color: $title-color;
              position: relative;
              display: flex;
              align-items: center;
              gap: 10px;
              &:not(:first-of-type) {
                margin-top: 14px;
              }
              .check {
                padding: 2px 8px 4px;
                border-radius: 0px 5px 0px 5px;
                background-color: $theme-color;
                transform: scale(0);
                position: absolute;
                right: -4px;
                top: -3px;
                [dir="rtl"] & {
                  left: -4px;
                  right: unset;
                  border-radius: 5px 0px 5px 0px;
                }
                img {
                  width: 15px;
                  height: 15px;
                }
              }
              .payment-card {
                width: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
                height: auto;
              }
              .discovery {
                [class="dark"] & {
                  display: none;
                }
              }
              .discovery-w {
                display: none;
                [class="dark"] & {
                  display: block;
                }
              }
              &.active {
                border-color: $theme-color;
                .check {
                  transform: scale(0.8);
                  transition: all 0.3s ease;
                }
              }
            }
          }
          .input-box {
            margin-bottom: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
            label {
              font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
            }
          }
          &.cash {
            .filter-row {
              .filter-col {
                font-weight: normal !important;
              }
            }
          }
        }
      }
    }
  }
  .order-detail {
    background-color: $grey-light;
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 18px;
    @include order-summery();
    h3 {
      margin-bottom: 6px;
    }
    ul {
      li {
        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }
  }
}
/// Payment Page Css End ///

/// Order Success Page Start ///
.order-success-page {
  .banner-section {
    .banner-wrap {
      text-align: center;
      margin-top: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
      img {
        width: 80%;
      }
    }
    .content-wrap {
      text-align: center;
      margin-top: 17px;
      h1 {
        text-transform: unset;
        font-weight: 700;
        font-family: $font-2;
      }
      p {
        margin-bottom: 0;
        font-weight: normal;
      }
    }
  }
  .order-id-section {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: $border1;
    gap: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
    .media {
      > span {
        background-color: $theme-color;
        padding:  calc(5px + (8 - 5) * ((100vw - 320px) / (1920 - 320)));
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        i {
          font-size: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320)));
          color: $white;
          [class="dark"] & {
            color: $white-text-dark;
          }
        }
      }
      .media-body {
        h2 {
          font-weight: 700;
          margin-bottom: calc(-5px + (-0 + 5) * ((100vw - 320px) / (1920 - 320)));
        }
        span {
          font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }
  }
  .order-detail {
    background-color: $grey-light;
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 18px;
    @include order-summery();
    h3 {
      margin-bottom: 6px;
      font-weight: 700;
    }
    ul {
      li {
        &:last-of-type {
          padding-bottom: 0;
          span {
            &:last-of-type {
              font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
            }
          }
        }
      }
    }
  }
}
/// Order Success Page End ///
