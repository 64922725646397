/*=====================
   1.2 Typography CSS 
==========================*/
/// Mulish Font Start ///

@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/mulish/mulish-v7-latin-200.eot");
  src: local(""), url("../fonts/mulish/mulish-v7-latin-200.eot?#iefix") format("embedded-opentype"), url("../fonts/mulish/mulish-v7-latin-200.woff2") format("woff2"),
    url("../fonts/mulish/mulish-v7-latin-200.woff") format("woff"), url("../fonts/mulish/mulish-v7-latin-200.ttf") format("truetype"),
    url("../fonts/mulish/mulish-v7-latin-200.svg#Mulish") format("svg");
}

@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/mulish/mulish-v7-latin-300.eot");
  src: local(""), url("../fonts/mulish/mulish-v7-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/mulish/mulish-v7-latin-300.woff2") format("woff2"),
    url("../fonts/mulish/mulish-v7-latin-300.woff") format("woff"), url("../fonts/mulish/mulish-v7-latin-300.ttf") format("truetype"),
    url("../fonts/mulish/mulish-v7-latin-300.svg#Mulish") format("svg");
}

@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/mulish/mulish-v7-latin-regular.eot");
  src: local(""), url("../fonts/mulish/mulish-v7-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/mulish/mulish-v7-latin-regular.woff2") format("woff2"),
    url("../fonts/mulish/mulish-v7-latin-regular.woff") format("woff"), url("../fonts/mulish/mulish-v7-latin-regular.ttf") format("truetype"),
    url("../fonts/mulish/mulish-v7-latin-regular.svg#Mulish") format("svg");
}

@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/mulish/mulish-v7-latin-500.eot");
  src: local(""), url("../fonts/mulish/mulish-v7-latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/mulish/mulish-v7-latin-500.woff2") format("woff2"),
    url("../fonts/mulish/mulish-v7-latin-500.woff") format("woff"), url("../fonts/mulish/mulish-v7-latin-500.ttf") format("truetype"),
    url("../fonts/mulish/mulish-v7-latin-500.svg#Mulish") format("svg");
}

@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/mulish/mulish-v7-latin-600.eot");
  src: local(""), url("../fonts/mulish/mulish-v7-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/mulish/mulish-v7-latin-600.woff2") format("woff2"),
    url("../fonts/mulish/mulish-v7-latin-600.woff") format("woff"), url("../fonts/mulish/mulish-v7-latin-600.ttf") format("truetype"),
    url("../fonts/mulish/mulish-v7-latin-600.svg#Mulish") format("svg");
}

@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/mulish/mulish-v7-latin-800.eot");
  src: local(""), url("../fonts/mulish/mulish-v7-latin-800.eot?#iefix") format("embedded-opentype"), url("../fonts/mulish/mulish-v7-latin-800.woff2") format("woff2"),
    url("../fonts/mulish/mulish-v7-latin-800.woff") format("woff"), url("../fonts/mulish/mulish-v7-latin-800.ttf") format("truetype"),
    url("../fonts/mulish/mulish-v7-latin-800.svg#Mulish") format("svg");
}

@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/mulish/mulish-v7-latin-700.eot");
  src: local(""), url("../fonts/mulish/mulish-v7-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/mulish/mulish-v7-latin-700.woff2") format("woff2"),
    url("../fonts/mulish/mulish-v7-latin-700.woff") format("woff"), url("../fonts/mulish/mulish-v7-latin-700.ttf") format("truetype"),
    url("../fonts/mulish/mulish-v7-latin-700.svg#Mulish") format("svg");
}

@font-face {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/mulish/mulish-v7-latin-900.eot");
  src: local(""), url("../fonts/mulish/mulish-v7-latin-900.eot?#iefix") format("embedded-opentype"), url("../fonts/mulish/mulish-v7-latin-900.woff2") format("woff2"),
    url("../fonts/mulish/mulish-v7-latin-900.woff") format("woff"), url("../fonts/mulish/mulish-v7-latin-900.ttf") format("truetype"),
    url("../fonts/mulish/mulish-v7-latin-900.svg#Mulish") format("svg");
}
/// Mulish Font End ///

/// Quicksand Font Start ///
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/quicksand/quicksand-v24-latin-300.eot");
  src: local(""), url("../fonts/quicksand/quicksand-v24-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/quicksand/quicksand-v24-latin-300.woff2") format("woff2"),
    url("../fonts/quicksand/quicksand-v24-latin-300.woff") format("woff"), url("../fonts/quicksand/quicksand-v24-latin-300.ttf") format("truetype"),
    url("../fonts/quicksand/quicksand-v24-latin-300.svg#Quicksand") format("svg");
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/quicksand/quicksand-v24-latin-regular.eot");
  src: local(""), url("../fonts/quicksand/quicksand-v24-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/quicksand/quicksand-v24-latin-regular.woff2") format("woff2"),
    url("../fonts/quicksand/quicksand-v24-latin-regular.woff") format("woff"), url("../fonts/quicksand/quicksand-v24-latin-regular.ttf") format("truetype"),
    url("../fonts/quicksand/quicksand-v24-latin-regular.svg#Quicksand") format("svg");
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/quicksand/quicksand-v24-latin-500.eot");
  src: local(""), url("../fonts/quicksand/quicksand-v24-latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/quicksand/quicksand-v24-latin-500.woff2") format("woff2"),
    url("../fonts/quicksand/quicksand-v24-latin-500.woff") format("woff"), url("../fonts/quicksand/quicksand-v24-latin-500.ttf") format("truetype"),
    url("../fonts/quicksand/quicksand-v24-latin-500.svg#Quicksand") format("svg");
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/quicksand/quicksand-v24-latin-600.eot");
  src: local(""), url("../fonts/quicksand/quicksand-v24-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/quicksand/quicksand-v24-latin-600.woff2") format("woff2"),
    url("../fonts/quicksand/quicksand-v24-latin-600.woff") format("woff"), url("../fonts/quicksand/quicksand-v24-latin-600.ttf") format("truetype"),
    url("../fonts/quicksand/quicksand-v24-latin-600.svg#Quicksand") format("svg");
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/quicksand/quicksand-v24-latin-700.eot");
  src: local(""), url("../fonts/quicksand/quicksand-v24-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/quicksand/quicksand-v24-latin-700.woff2") format("woff2"),
    url("../fonts/quicksand/quicksand-v24-latin-700.woff") format("woff"), url("../fonts/quicksand/quicksand-v24-latin-700.ttf") format("truetype"),
    url("../fonts/quicksand/quicksand-v24-latin-700.svg#Quicksand") format("svg");
}
/// Quicksand Font End ///

body {
  font-family: $font-1;
}
ul {
  list-style: none;
}
li {
  font-size: 14px;
  color: $title-color;
}

p {
  font-size: 14px;
  line-height: 1.5;
  color: $title-color;
}

a {
  transition: 0.5s ease;
  text-decoration: none;
  color: $title-color;

  &:hover {
    text-decoration: none;
    transition: 0.5s ease;
    color: currentColor;
  }
  &:focus {
    outline: none;
  }
}

:focus {
  outline: none;
}

.form-control {
  font-size: 14px;
  color: $title-color;
  [dir="rtl"] & {
    text-align: right;
  }
  &:focus {
    box-shadow: none;
  }
}

h1 {
  font-size: 38px;
  line-height: 33px;
  text-transform: capitalize;
  margin: 0;
  color: $title-color;
}

h2 {
  font-size: 24px;
  line-height: 22px;
  margin: 0;
  color: $title-color;
}

h3 {
  font-size: 20px;
  line-height: 16px;
  margin: 0;
  color: $title-color;
}

h4 {
  font-size: 18px;
  line-height: 25px;
  margin: 0;
  color: $title-color;
}

h5 {
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  font-weight: 400;
  color: $title-color;
}

h6 {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  margin: 0;
  color: $title-color;
}

.font-base {
  font-size: 14px !important;
}

/// Font Size ///
$theme-font-size: (
  xs: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320))),
  sm: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))),
  md: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320))),
  lg: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320))),
  xl: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320))),
  xxl: calc(24px + (30 - 24) * ((100vw - 320px) / (1920 - 320))),
  default: 16px,
);
@each $size, $value in $theme-font-size {
  .font-#{$size} {
    font-size: $value !important;
  }
}
.title-2 {
  font-weight: 600;
  font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
}
.title-color {
  color: $title-color;
}
.font-white {
  color: $white;
}
.content-color {
  color: $content-color;
}
.font-theme {
  color: $theme-color !important;
}
.font-danger {
  color: $theme-danger !important;
}
