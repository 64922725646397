/*=====================
 4.1 About Us Pages Css 
==========================*/
.about-us-page {
  .banner-box {
    background-color: $theme-light-color;
    border-radius: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
    img {
      width: 100%;
      margin-bottom: -10%;
    }
  }
  .who-we-are {
    padding-top: calc(50px + (60 - 50) * ((100vw - 320px) / (1920 - 320)));
    h2 {
      font-weight: 700;
      margin-bottom: 4px;
      margin-top: -5px;
      font-size: 16px;
    }
    p {
      margin-bottom: 0;
    }
  }
  .how-do-section {
    h3 {
      font-weight: 700;
      margin-top: -3px;
      font-size: 16px;
    }
    .steps-wrap {
      .steps-box {
        margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
        padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320))) calc(26px + (30 - 26) * ((100vw - 320px) / (1920 - 320)));
        background-color: $grey-light;
        margin-left: calc(14.5px + (16.5 - 14.5) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 5px;
        position: relative;
        [dir="rtl"] & {
          margin-right: calc(14.5px + (16.5 - 14.5) * ((100vw - 320px) / (1920 - 320)));
          margin-left: unset;
        }
        > span {
          width: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320)));
          height: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320)));
          display: block;
          font-weight: 600;
          color: $white;
          background-color: $theme-color;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: calc(-14.5px + (-16.5 + 14.5) * ((100vw - 320px) / (1920 - 320)));
          font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
          top: 50%;
          transform: translateY(-50%);
          [dir="rtl"] & {
            right: calc(-14.5px + (-16.5 + 14.5) * ((100vw - 320px) / (1920 - 320)));
            left: unset;
          }
          [class="dark"] & {
            color: $white-text-dark;
          }
        }
        .content {
          h4 {
            font-weight: 500;
            line-height: 18px;
            margin-bottom: 5px;
            margin-top: -3px;
            font-size: 15px;
          }
          p {
            margin-bottom: 0;
            line-height: 20px;
          }
        }
      }
    }
  }
  .team-section {
    .gap-y {
      --bs-gutter-y: calc(40px + (70 - 40) * ((100vw - 320px) / (1920 - 320)));
    }
    h5 {
      font-weight: 700;
      margin-bottom: 15px;
    }
    .member {
      padding-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
      .bg-shape {
        width: 100%;
        height: 50%;
        text-align: center;
        border-radius: 10px;
        img {
          margin-top: calc(-20px + (-40 - -20) * ((100vw - 320px) / (1920 - 320)));
          width: 50%;
        }
      }
      h6 {
        font-weight: 600;
        text-align: center;
        margin-top: 10px;
        font-size: 15px;
        color: $content-color;
      }
      .social {
        margin-top: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: -4px;
        li {
          svg {
            width: calc(22px + (27 - 22) * ((100vw - 320px) / (1920 - 320)));
            height: calc(22px + (27 - 22) * ((100vw - 320px) / (1920 - 320)));
            fill: $theme-color;
          }
        }
      }
    }
  }
}
