/*=====================
 4.16 Order History Pages Css 
==========================*/
.order-history {
  .search-box {
    @include search-box-side;
  }
  .tab-content {
    .tab-pane {
      .order-box {
        background-color: $grey-light;
        padding: 15px;
        border-radius: 10px;
        &:not(:first-of-type) {
          margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
        }
        .media {
          justify-content: space-between;
          .content-box {
            h2 {
              font-weight: 600;
              margin-bottom: 3px;
            }
            p {
              margin-bottom: 3px;
              width: calc(180px + (245 - 180) * ((100vw - 320px) / (1920 - 320)));
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .media-body {
            img {
              width: calc(75px + (100 - 75) * ((100vw - 320px) / (1920 - 320)));
              height: calc(75px + (100 - 75) * ((100vw - 320px) / (1920 - 320)));
              border-radius: 10px;
            }
          }
        }
        .bottom-content {
          margin-top: 15px;
          padding-top: 10px;
          border-top: $border1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .give-rating {
            &:hover {
              color: $content-color;
            }
          }
          .rating {
            display: none;
          }
          &.active {
            .give-rating {
              display: none;
            }
            .rating {
              display: flex;
            }
          }
        }
      }
    }
  }
}
