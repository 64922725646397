/*=====================
 4.10 Help CSS 
==========================*/
.help-page {
  .search-box {
    @include search-box;
  }
  .banner {
    text-align: center;
    img {
      width: 50%;
    }
  }
  .questions-section {
    h1 {
      margin-bottom: 10px;
      font-weight: 700;
      margin-top: -6px;
      font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320))) !important;
    }
    .accordion {
      .accordion-item {
        background-color: $white;
        .accordion-header {
          padding: 14px 0;
        }
        .accordion-body {
          padding: 0 0 14px 0;
          margin-top: -5px;
        }
      }
    }
  }
}
