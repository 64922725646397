/*=====================
 2.7 Ratio CSS
==========================*/

.ratio_40 {
  .bg-size {
    &:before {
      padding-top: 40%;
      content: "";
      display: block;
    }
  }
}
.ratio_45 {
  .bg-size {
    &:before {
      padding-top: 45%;
      content: "";
      display: block;
    }
  }
}
.ratio2_1 {
  .bg-size {
    &:before {
      padding-top: 50%;
      content: "";
      display: block;
    }
  }
}
.ratio2_3 {
  .bg-size {
    &:before {
      padding-top: 60%;
      content: "";
      display: block;
    }
  }
}
.ratio-68 {
  .bg-size {
    &:before {
      padding-top: 68%;
      content: "";
      display: block;
    }
  }
}
.ratio3_2 {
  .bg-size {
    &:before {
      padding-top: 66.66%;
      content: "";
      display: block;
    }
  }
}
.ratio_landscape {
  .bg-size {
    &:before {
      padding-top: 75%;
      content: "";
      display: block;
    }
  }
}
.ratio-83 {
  .bg-size {
    &:before {
      padding-top: 83%;
      content: "";
      display: block;
    }
  }
}
.ratio_square {
  .bg-size {
    &:before {
      padding-top: 100%;
      content: "";
      display: block;
    }
  }
}
.ratio_94 {
  .bg-size {
    &:before {
      padding-top: 94%;
      content: "";
      display: block;
    }
  }
}
.ratio_115 {
  .bg-size {
    &:before {
      padding-top: 115%;
      content: "";
      display: block;
    }
  }
}
.ratio_125 {
  .bg-size {
    &:before {
      padding-top: 125%;
      content: "";
      display: block;
    }
  }
}
.ratio_123 {
  .bg-size {
    &:before {
      padding-top: 123%;
      content: "";
      display: block;
    }
  }
}
.ratio_asos {
  .bg-size {
    &:before {
      padding-top: 127.7777778%;
      content: "";
      display: block;
    }
  }
}
.ratio_portrait {
  .bg-size {
    &:before {
      padding-top: 150%;
      content: "";
      display: block;
    }
  }
}
.ratio1_2 {
  .bg-size {
    &:before {
      padding-top: 200%;
      content: "";
      display: block;
    }
  }
}
.b-top {
  background-position: top !important;
}
.b-bottom {
  background-position: bottom !important;
}
.b-center {
  background-position: center !important;
}
.b_size_content {
  background-size: contain !important;
  background-repeat: no-repeat;
}
