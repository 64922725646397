/*=====================
 4.20 Setting Pages Css 
==========================*/
.setting-page {
  .user-panel {
    margin-bottom: calc(14px + (19 - 14) * ((100vw - 320px) / (1920 - 320)));
    width: 100%;
    padding: 17px;
    border-radius: 7px;
    .media {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .avatar-wrap {
        position: relative;
        > a {
          img {
            width: calc(80px + (120 - 80) * ((100vw - 320px) / (1920 - 320)));
            height: calc(80px + (120 - 80) * ((100vw - 320px) / (1920 - 320)));
            display: block;
            border-radius: 100%;
            object-fit: cover;
          }
        }
        .edit {
          position: absolute;
          cursor: pointer;
          width: calc(27px + (30 - 27) * ((100vw - 320px) / (1920 - 320)));
          height: calc(27px + (30 - 27) * ((100vw - 320px) / (1920 - 320)));
          background-color: $theme-color;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          right: 0;
          bottom: calc(0px + (10 - 0) * ((100vw - 320px) / (1920 - 320)));
          [dir="rtl"] & {
            left: 0;
            right: unset;
          }
          svg {
            width: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
            height: calc(15px + (17 - 15) * ((100vw - 320px) / (1920 - 320)));
            stroke: $white;
            [class="dark"] & {
              stroke: $white-text-dark;
            }
          }
        }
      }
      .media-body {
        text-align: center;
        h2 {
          font-weight: 600;
          font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
          margin-bottom: calc(-3px + (-0 + 3) * ((100vw - 320px) / (1920 - 320)));
        }
      }
    }
  }
  .custom-form {
    button {
      display: block;
      width: 100%;
      margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    }
  }
}
